<template>
  <div class="container">
    <a :href="link" target="_blank"><h2>{{ link }}</h2></a>
    <button v-if="link" @click="copyLink">Copiar Link</button>
    <div v-if="link" class="qrcode-container">
      <canvas ref="qrcodeCanvas"></canvas>
      <div class="button-group">
        <button @click="downloadQRCode">Baixar QR Code</button>
        <button @click="printQRCode">Imprimir</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import QRCode from "qrcode";

const baseUrl = require("../constants/constants");
const getMenuApi = `${baseUrl}/menu`;

const LINK_APP_FLUTTER =
  process.env.VUE_APP_LINK_LOCAL || "https://app.tochamenu.com";

export default {
  data() {
    return {
      link: "",
    };
  },
  methods: {
    async fetchMenu() {
      try {
        const response = await axios.get(getMenuApi, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        if (response) {
          this.link = `${LINK_APP_FLUTTER}/${response.data.data[0].hash}`;
          this.$nextTick(() => {
            this.generateQRCode();
          });
        }
      } catch (error) {
        console.error("Erro ao buscar o menu:", error);
      }
    },
    async generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      if (!canvas) {
        console.error("Canvas element not found.");
        return;
      }
      try {
        await QRCode.toCanvas(canvas, this.link, {
          width: 200,
        });
      } catch (err) {
        console.error("Erro ao gerar o QR Code:", err);
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link)
        .then(() => alert("Link copiado para a área de transferência!"))
        .catch(err => console.error("Erro ao copiar o link:", err));
    },
    downloadQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      if (!canvas) {
        console.error("Canvas element not found.");
        return;
      }
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "qrcode.png";
      link.click();
    },
    printQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      if (!canvas) {
        console.error("Canvas element not found.");
        return;
      }
      const dataUrl = canvas.toDataURL("image/png");
      const windowContent = `
        <!DOCTYPE html>
        <html>
          <head><title>Print QR Code</title></head>
          <body>
            <img src="${dataUrl}">
          </body>
        </html>`;
      const printWin = window.open("", "_blank", "fullscreen=yes");
      printWin.document.open();
      printWin.document.write(windowContent);
      printWin.document.close();
      printWin.onload = () => {
        printWin.print();
        printWin.onafterprint = () => {
          printWin.close();
        };
      };
    },
  },
  mounted() {
    this.fetchMenu();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qrcode-container {
  margin-top: 15px;
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: 10px; 
  margin-top: 10px;
}

a {
  color: #007bff;
}

button {
  margin: 5px;
}
</style>
