<template>
  <div class="login-container">
    <div class="login-box">
      <img src="@/assets/icons/logo.png" alt="TochaMenu Logo" class="logo" />
      <h2>TochaHub</h2>
      <h3>Boas-vindas</h3>
      <form @submit.prevent="handleLogin">
        <div class="input-group">
          <label for="username">Seu E-mail</label>
          <input
            type="text"
            v-model="username"
            id="username"
            placeholder="Insira seu E-mail"
            required
          />
        </div>
        <div class="input-group">
          <label for="password">Sua Senha</label>
          <div class="password-input-wrapper">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              id="password"
              placeholder="Insira sua Senha"
              required
            />
            <span
              class="toggle-password"
              @click="toggleShowPassword"
              v-if="password.length > 0"
            >
              <img
                :src="
                  showPassword
                    ? require('@/assets/icons/eye-open.png')
                    : require('@/assets/icons/eye-closed.png')
                "
                alt="Toggle Password Visibility"
              />
            </span>
          </div>
        </div>
        <button type="submit" class="login-button">Acessar</button>
      </form>
      <a href="#" class="forgot-password">Esqueceu sua senha?</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const baseUrl = require("../constants/constants");

export default {
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
  created() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("authToken");
      if (token) {
        this.$router.push("/dashboard");
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    async handleLogin() {
      try {
        if (!axios) {
          throw new Error(
            'Axios não está instalado. Execute "npm install axios" para instalá-lo.'
          );
        }

        const apiUrl = baseUrl + "/login";

        const response = await axios.post(
          apiUrl,
          {
            username: this.username,
            password: this.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const token = response.data.token;
          this.$store.dispatch("login", token);
          this.$router.push("/dashboard");
        } else {
          alert("Erro de autenticação, verifique suas credenciais.");
        }
      } catch (error) {
        console.error("Erro ao autenticar:", error);
        alert("Falha ao realizar login, tente novamente mais tarde.");
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}
.login-box {
  width: 400px;
  padding: 20px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.logo {
  width: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
}
h2,
h3 {
  text-align: center;
}
h2 {
  font-size: 24px;
  color: #333;
}
h3 {
  font-size: 24px;
  color: #0099cc;
  margin-bottom: 20px;
}
.input-group {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
}
label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.password-input-wrapper {
  position: relative;
}
.toggle-password {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}
.toggle-password img {
  width: 20px;
  height: 20px;
}
.login-button {
  width: calc(100% - 40px);
  margin: 0 20px;
  padding: 10px;
  background: #0099cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.login-button:hover {
  background: #007399;
}
.forgot-password {
  display: block;
  margin: 15px 20px 0 20px;
  color: #0099cc;
  text-decoration: none;
  text-align: left;
  width: calc(100% - 40px);
}
.forgot-password:hover {
  text-decoration: underline;
}
</style>
