<template>
  <div class="image-uploader">
    <label for="file-upload" class="upload-button"> Escolha uma imagem </label>
    <input
      type="file"
      id="file-upload"
      @change="onFileChange"
      accept="image/*"
      style="display: none"
    />

    <div v-if="previewImage" class="image-preview">
      <img :src="previewImage" alt="Pré-visualização" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
const FormData = require("form-data");
const baseUrl = require("../constants/constants");
const token = localStorage.getItem("authToken");

const headers = {
  authorization: "Bearer " + token,
  "Content-Type": "multipart/form-data",
};

const uploadMediaApi = baseUrl + `/upload-media`;

export default {
  data() {
    return {
      selectedFile: null,
      previewImage: null,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.previewImage = URL.createObjectURL(file);
      }
    },
    async uploadImage() {
      if (!this.selectedFile) return;

      const CHUNK_SIZE = 1024 * 1024;
      const totalChunks = Math.ceil(this.selectedFile.size / CHUNK_SIZE);
      const fileId = Date.now();

      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(this.selectedFile.size, start + CHUNK_SIZE);
        const chunkBlob = this.selectedFile.slice(start, end);

        const chunk = new File([chunkBlob], this.selectedFile.name, {
          type: this.selectedFile.type,
        });

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("fileId", fileId);
        formData.append("chunkIndex", i);
        formData.append("totalChunks", totalChunks);

        try {
          await axios.post(uploadMediaApi, formData, {
            headers: headers,
          });
        } catch (error) {
          console.error("Erro ao enviar chunk:", error);
          alert("Erro no upload da mídia");
          return;
        }
      }

      alert("Upload de imagem concluído!");
      this.$emit(
        "image-uploaded",
        `${baseUrl}/uploads/${this.selectedFile.name}`
      );
    },
  },
};
</script>

<style scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.image-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
}
</style>
