<template>
  <div>
    <!--
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Filtrar..."
      @input="filterEntities"
      class="combo-input"
    />
    -->
    <select v-model="selectedEntity" @change="handleSelect" class="combo-input">
      <option
        v-for="entity in filteredEntities"
        :key="entity.id"
        :value="entity.id"
      >
        {{ entity.name }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import { ref, computed, watch } from "vue";

export default {
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const entities = ref([]);
    const selectedEntity = ref(null);
    const searchQuery = ref("");

    const fetchEntities = async () => {
      try {
        const response = await axios.get(props.apiUrl, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        entities.value = response.data.data;
      } catch (error) {
        console.error(`Erro ao buscar ${props.entityName}:`, error);
      }
    };

    const filteredEntities = computed(() => {
      return entities.value.filter((entity) =>
        entity.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const handleSelect = () => {
      emit(`selected_${props.entityName}_id`, selectedEntity.value);
    };

    fetchEntities();

    watch(() => props.apiUrl, fetchEntities);

    return {
      entities,
      selectedEntity,
      searchQuery,
      filteredEntities,
      handleSelect,
    };
  },
};
</script>

<style scoped>
.combo-input {
  padding: 12px;
  margin-bottom: 8px;
}
</style>