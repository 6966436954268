<template>
  <div>
    <div class="header">
      <input v-model="search" placeholder="Pesquisar" @input="onSearch" />
      <button @click="openCreateModal">+ Nova categoria</button>
    </div>

    <table>
      <thead>
        <tr>
          <th>Thumb</th>
          <th>Nome</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td>
            <img
              :src="category.thumbUrl"
              alt="Thumbnail"
              width="120"
              height="120"
            />
          </td>
          <td>{{ category.name }}</td>
          <td>
            <button @click="deleteCategory(category.id)">Remover</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showCreateModal" class="modal">
      <div class="modal-content">
        <h3>Criar Categoria</h3>
        <input
          class="custom-input"
          v-model="newCategory.name"
          placeholder="Nome da categoria"
        />

        <ImageUploader ref="uploader" @image-uploaded="setImageUrl" />

        <button class="custom-button-success" @click="initUpload">Criar</button>
        <button class="custom-button-cancel" @click="closeCreateModal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "../components/ImageUploader.vue";
import store from "../store";
const baseUrl = require("../constants/constants");

const getCategoriasApi = baseUrl + `/categories`;
const addCategoryApi = baseUrl + `/category`;
const removeCategoryApi = baseUrl + `/category`;

export default {
  data() {
    return {
      categories: [],
      search: "",
      showCreateModal: false,
      newCategory: {
        name: "",
        thumbUrl: undefined,
      },
      page: 1,
      limit: 10,
      searchTimeout: null,
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const params = {
          limit: this.limit,
          page: this.page,
        };
        if (this.search) {
          params.name = this.search;
        }
        const response = await axios.get(getCategoriasApi, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
          params,
        });
        this.categories = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    },
    onSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 1; 
        this.fetchCategories();
      }, 300);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.resetNewCategory();
      this.showCreateModal = false;
    },
    setImageUrl(url) {
      this.newCategory.thumbUrl = url;
      this.createCategory();
    },
    initUpload() {
      this.$refs.uploader.uploadImage();
    },
    async createCategory() {
      try {
        const response = await axios.post(addCategoryApi, this.newCategory, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        if (response) {
          this.resetNewCategory();
          this.fetchCategories();
          this.closeCreateModal();
        }
      } catch (error) {
        console.error("Erro ao criar categoria:", error);
        alert("Ocorreu um erro ao tentar criar a categoria");
      }
    },
    async deleteCategory(categoryId) {
      try {
        await axios.delete(`${removeCategoryApi}/${categoryId}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        this.fetchCategories();
      } catch (error) {
        console.error("Erro ao remover categoria:", error);
      }
    },
    resetNewCategory() {
      this.newCategory = {
        name: "",
        thumbUrl: undefined,
      };
    },
  },
  mounted() {
    this.fetchCategories();
  },
  components: {
    ImageUploader,
  },
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: left;
}
img {
  border-radius: 5px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}
.custom-input {
  padding: 12px;
  margin-bottom: 8px;
}
.custom-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
.custom-button-success {
  background-color: #15b300;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
.custom-button-cancel {
  background-color: #e01313;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
</style>
