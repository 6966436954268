import { createStore } from 'vuex';
import { jwtDecode } from 'jwt-decode';

const store = createStore({
  state: {
    token: localStorage.getItem('authToken') || '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = '';
    },
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
      localStorage.setItem("authToken", token);
    },
    logout({ commit }) {
      commit('logout');
      localStorage.removeItem('authToken');
    },
  },
  getters: {
    isAuthenticated: state => {
      if (!state.token) return false;

      const tokenData = jwtDecode(state.token);
      const exp = tokenData.exp;
      const now = Math.floor(Date.now() / 1000);

      return exp > now;
    },
    getToken: state => state.token,
  },
});

export default store;
