<template>
  <div class="products-page">
    <div class="header">
      <input v-model="search" placeholder="Pesquisar" @input="onSearch" />
      <button @click="openAddProductModal">+ Novo produto</button>
    </div>

    <table>
      <thead>
        <tr>
          <th>Thumbnail</th>
          <th>Descrição</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">
          <td>
            <img
              :src="product.mediasUrl[0].thumb_url || product.mediasUrl[0].url"
              alt="Thumbnail"
              class="product-thumbnail"
            />
          </td>
          <td>{{ product.description }}</td>
          <td>
            <button @click="deleteProduct(product.id)">Remover</button>
          </td>
        </tr>
      </tbody>
    </table>

    <AddProductModal
      v-if="isAddProductModalOpen"
      @close="isAddProductModalOpen = false"
      @productAdded="fetchProducts"
    />
  </div>
</template>

<script>
import axios from "axios";
import AddProductModal from "@/components/AddProductModal.vue";
import store from "../store";
const baseUrl = require("../constants/constants");
const getProductsApi = baseUrl + `/products`;
const removeProductApi = baseUrl + `/product`;

export default {
  data() {
    return {
      products: [],
      page: 1,
      limit: 20,
      search: "",
      isAddProductModalOpen: false,
      searchTimeout: null,
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const params = {
          limit: this.limit,
          page: this.page,
        };
        if (this.search) {
          params.description = this.search;
        }
        const response = await axios.get(getProductsApi, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
          params,
        });
        this.products = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    },
    onSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 1; 
        this.fetchProducts();
      }, 300); 
    },
    openAddProductModal() {
      this.isAddProductModalOpen = true;
    },
    async deleteProduct(productId) {
      try {
        await axios.delete(`${removeProductApi}/${productId}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        this.fetchProducts();
      } catch (error) {
        console.error("Erro ao deletar produto:", error);
      }
    },
  },
  mounted() {
    this.fetchProducts();
  },
  components: {
    AddProductModal,
  },
};
</script>

<style scoped>
.product-thumbnail {
  width: 120px;
  height: 120px;
}
</style>
