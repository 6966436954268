<template>
  <div class="media-uploader">
    <label for="file-upload" class="upload-button">Selecione as mídias</label>
    <input
      type="file"
      id="file-upload"
      @change="onFileChange"
      accept="image/*,video/*"
      style="display: none"
      multiple
    />

    <div class="media-preview" v-if="selectedFiles.length">
      <div
        v-for="(item, index) in selectedFiles"
        :key="index"
        class="media-item"
      >
        <img
          v-if="
            item.file && item.file.type && item.file.type.startsWith('image/')
          "
          :src="item.preview"
          alt="Pré-visualização da imagem"
        />

        <video
          v-else-if="
            item.file && item.file.type && item.file.type.startsWith('video/')
          "
          controls
          :src="item.preview"
          alt="Pré-visualização do vídeo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const FormData = require("form-data");
const baseUrl = require("../constants/constants");
const token = localStorage.getItem("authToken");

const headers = {
  authorization: "Bearer " + token,
  "Content-Type": "multipart/form-data",
};

const uploadMediaApi = baseUrl + `/upload-media`;

export default {
  data() {
    return {
      selectedFiles: [],
    };
  },
  methods: {
    onFileChange(event) {
      const files = Array.from(event.target.files).filter((file) => file.type);
      this.selectedFiles = files.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        thumbnail: null,
      }));
    },
    async uploadAllMedia() {
      const uploadedUrls = [];

      for (let media of this.selectedFiles) {
        if (media.file.type.startsWith("video/")) {
          media.thumbnail = await this.createThumbnail(media.file);
        }
        let thumbnailUrl = null;
        const url = await this.uploadMedia(media.file);
        if (media.thumbnail != null) {
          thumbnailUrl = await this.uploadMedia(media.thumbnail);
        }
        if (url) {
          uploadedUrls.push({ media_url: url, thumbnail_url: thumbnailUrl });
        }
      }

      if (uploadedUrls.length > 0) {
        this.$emit("media-uploaded", uploadedUrls);
        alert("Upload de mídias concluído!");
      } else {
        alert("Erro no upload da(s) mídia(s)");
      }
    },
    async createThumbnail(videoFile) {
      return new Promise((resolve) => {
        const videoURL = URL.createObjectURL(videoFile);
        const video = document.createElement("video");

        video.src = videoURL;
        video.addEventListener("loadeddata", () => {
          video.currentTime = 1;
        });

        video.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = 320;
          canvas.height = 240;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            const thumbnailFile = new File([blob], videoFile.name + "_thumb.png", {type: "image/png"});
            resolve(thumbnailFile);
          });
          URL.revokeObjectURL(videoURL);
        });
      });
    },
    async uploadMedia(file) {
      const CHUNK_SIZE = 1024 * 1024;
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      const fileId = Date.now();
      let uploadUrl = "";

      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const chunkBlob = file.slice(start, end);

        const chunk = new File([chunkBlob], file.name, {
          type: file.type,
        });

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("fileId", fileId);
        formData.append("chunkIndex", i);
        formData.append("totalChunks", totalChunks);

        try {
          const response = await axios.post(uploadMediaApi, formData, {
            headers: headers,
          });

          if (i === totalChunks - 1 && response.data.imageUrl) {
            uploadUrl = `${baseUrl}/uploads/${file.name}`;
          }
        } catch (error) {
          console.error("Erro ao enviar chunk:", error);
          return null;
        }
      }

      return uploadUrl;
    },
  },
};
</script>
<style>
.media-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.media-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.media-item img,
.media-item video {
  max-width: 100px;
  max-height: 100px;
}
</style>
