<template>
  <div class="modal">
    <div class="modal-content">
      <h3>Criar Produto</h3>

      <div>
        <label for="category_id">Selecionar Categoria:</label>
        <ComboBox
          :apiUrl="getCategoriesApi"
          entityName="category"
          @selected_category_id="handleCategorySelected"
        />
      </div>

      <div>
        <label for="major_price">Preço Majoritário:</label>
        <input
          class="custom-input"
          type="number"
          v-model="newProduct.major_price"
          required
        />
      </div>

      <div>
        <label for="minor_price">Preço Minoritário:</label>
        <input
          class="custom-input"
          type="number"
          v-model="newProduct.minor_price"
          required
        />
      </div>

      <div>
        <label for="description">Descrição:</label>
        <input class="custom-input" v-model="newProduct.description" required />
      </div>

      <MediasUploader ref="uploader" @media-uploaded="handleMediaUpload" />

      <button class="custom-button-success" @click="initUpload">
        Criar
      </button>
      <button class="custom-button-cancel" @click="closeCreateModal">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MediasUploader from "@/components/MediasUploader.vue";
import ComboBox from "@/components/EntityComboBox.vue";
import store from "../store";
const baseUrl = require("../constants/constants");
const addProductApi = baseUrl + `/product`;

export default {
  data() {
    return {
      newProduct: {
        category_id: "",
        major_price: "",
        minor_price: "",
        description: "",
        amount: 0,
        mediasUrl: [],
      },
      getCategoriesApi: baseUrl + "/categories?page=1&limit=50",
    };
  },
  methods: {
    handleMediaUpload(uploadedUrls) {
      this.newProduct.mediasUrl = [...uploadedUrls];
      this.createProduct();
    },

    handleCategorySelected(categoryId) {
      this.newProduct.category_id = categoryId;
    },

    async createProduct() {
      try {
        const response = await axios.post(addProductApi, this.newProduct, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + store.getters.getToken,
          },
        });
        if (response) {
          this.resetNewProduct();
          this.$emit("productAdded");
          this.$emit("close");
        }
      } catch (error) {
        console.error("Erro ao adicionar produto:", error);
        alert("Ocorreu um erro ao tentar cadastrar o produtos.");
      }
    },

    closeCreateModal() {
      this.resetNewProduct();
      this.$emit("close");
    },

    resetNewProduct() {
      this.newProduct = {
        category_id: "",
        major_price: "",
        minor_price: "",
        description: "",
        amount: 0,
        mediasUrl: [],
      };
    },

    initUpload() {
      this.$refs.uploader.uploadAllMedia();
    },
  },
  components: {
    MediasUploader,
    ComboBox,
  },
};
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}
.custom-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
.custom-button-success {
  background-color: #15b300;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
.custom-button-cancel {
  background-color: #e01313;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
  margin-inline: 8px;
  cursor: pointer;
}
.custom-input {
  padding: 12px;
  margin-bottom: 8px;
}
</style>
